import type { FC } from 'react';

import clientSideRendering from './csr';
import serverSideRendering from './ssr';

export function createRenderer(rootComponent: any) {
	const renderer = import.meta.env.SSR ? serverSideRendering : clientSideRendering;

	return renderer(rootComponent);
}

export const renderPages = (App: FC<any>) => {
	const render = createRenderer(App);
	if (!import.meta.env.SSR) {
		const json = document.getElementById('json-root')?.innerHTML;
		const rootProps = json ? JSON.parse(json) : {};

		render(rootProps);
	}
	return render;
};
