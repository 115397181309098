import { genKey } from '@babylon/ui-kit-helpers/keys';
import type { SliderProps } from '@babylon/ui-kit-structures/components/others/slider/types';
import type { FC } from 'react';
import { useTheme } from 'styled-components';

import { RenderStars } from '../renderStars';
import * as S from './styled';
import type { ReviewlistProps } from './types';

export const ReviewList: FC<ReviewlistProps> = ({ average, list = [], literal, views, pagination, arrows }) => {
	const theme = useTheme();

	const slider: SliderProps = {
		breakpoints: {
			[theme.breakpoints.xxl]: { perPage: 4 },
			[theme.breakpoints.xl]: { perPage: 3 },
			[theme.breakpoints.lg]: { perPage: 2 },
			[theme.breakpoints.md]: { perPage: 1 },
		},
	};

	return (
		<S.Carousel {...slider} pagination={pagination} perPage={views} perMove={views} arrows={arrows}>
			{list.map(item => (
				<S.Review key={genKey(item)}>
					<RenderStars average={average} stars={item.stars} date={item.date} dateText={literal} />
					<S.Title>{item.title}</S.Title>
					<S.Text>{item.text}</S.Text>
					<S.Name>{item.name}</S.Name>
				</S.Review>
			))}
		</S.Carousel>
	);
};

export * from '../types';
export default ReviewList;
