import Image from '@babylon/ui-kit-base/components/multimedia/image';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import type { FC } from 'react';

import { RenderStars } from '../renderStars';
import * as S from './styled';
import type { AverageProps } from './types';

export const Average: FC<AverageProps> = ({ average, literals, totalReviews = 0, reviewUrl }) => {
	const stars = Math.ceil(average ?? 5) as 1 | 2 | 3 | 4 | 5;
	const renderRatingText = () => literals?.[`average_${stars}`] ?? literals?.average_5;

	return (
		<S.Average>
			<S.RatingText>{renderRatingText()}</S.RatingText>
			<RenderStars average={average} stars={stars} />
			<S.AverageText>
				<InnerMarkdownHTML
					content={literals?.average_text?.replace('[NUMBER]', totalReviews.toString()) ?? ''}
				/>
			</S.AverageText>
			<a href={reviewUrl}>
				<Image
					src={'/comun/images/logos/svg/trustedshops.svg'}
					{...{
						width: 96,
						height: 50,
						alternative: literals?.altLogoLabel ?? '',
					}}
				/>
			</a>
		</S.Average>
	);
};

export * from '../types';
export default Average;
