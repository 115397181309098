import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

import { AverageStars } from '../../renderStars/styled';
import { Review } from '../../reviewList/styled';

export const Wrapper = styled.div`
	.splide {
		position: relative;
		width: 100%;
		padding: 0 23px;
		overflow: hidden;
	}

	.splide__pagination {
		position: unset;
		li {
			.splide__pagination__page {
				background-color: ${({ theme }) => theme.colors.grays.light};
				width: 16px;
				height: 16px;
			}
			.splide__pagination__page.is-active {
				background-color: ${({ theme }) => theme.colors.primary.light};
			}
		}
	}

	${Review} {
		padding: 4px;
		margin-top: 26px;
		margin-left: 0px;
		color: ${({ theme }) => theme.colors.grays.darker};
		${AverageStars} {
			margin-top: 20px;
			i {
				${FontMixin({ size: 'small', height: 'large', weight: 'medium' })}
			}
		}
	}
`;

export const TitleAverage = styled.div`
	${FontMixin({ size: 'medium', height: 'large', weight: 'bold' })}
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding: 16px 0 0;
`;

export const Text = styled.p`
	${FontMixin({ size: 'medium', height: 'large', weight: 'light' })}
	line-height: 16px;
	flex-grow: 1;
	margin: 8px 0;
`;

export const Name = styled.p`
	${FontMixin({ size: 'small', height: 'large', weight: 'light' })}
`;
