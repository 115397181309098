import Section from '@babylon/ui-kit-structures/components/others/section';
import styled from 'styled-components';

export const SectionWithBorder = styled(Section)`
	background-color: ${({ theme }) => theme.colors.white.base};
	border-top: 1px solid ${({ theme }) => theme.colors.grays.light};
	border-bottom: 1px solid ${({ theme }) => theme.colors.grays.light};
	padding: 40px 0;
`;

export const SectionBgSecondary = styled(Section)`
	background-color: ${({ theme }) => theme.colors.primary.light};
	margin-bottom: 0px;
`;
