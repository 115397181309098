import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { Stars } from '@babylon/ui-kit-styles/common/mixins/stars.styled';
import styled from 'styled-components';

export const AverageStars = styled.div<{ rate: number }>`
	${FlexMixin({ align: 'center', justify: 'space-between' })}
	${Stars} {
		${FlexMixin({ align: 'center', justify: 'space-between' })}
		i {
			padding: 2px;
			color: ${props => props.theme.colors.thirdParty['trustedshops']};
			${FontMixin({ size: 'larger', height: 'large', weight: 'medium' })}
		}
		.star-25 {
			i {
				background: linear-gradient(
					90deg,
					${props => props.theme.colors.thirdParty['trustedshops']} 40%,
					${props => props.theme.colors.grays.light} 40%,
					${props => props.theme.colors.grays.light} 100%
				);
				-webkit-background-clip: text;
				background-clip: text;
				color: transparent;
			}
		}
		.star-50 {
			i {
				background: linear-gradient(
					90deg,
					${props => props.theme.colors.thirdParty['trustedshops']} 50%,
					${props => props.theme.colors.grays.light} 50%,
					${props => props.theme.colors.grays.light} 100%
				);
				-webkit-background-clip: text;
				background-clip: text;
				color: transparent;
			}
		}

		.star-75 {
			i {
				background: linear-gradient(
					90deg,
					${props => props.theme.colors.thirdParty['trustedshops']} 60%,
					${props => props.theme.colors.grays.light} 60%,
					${props => props.theme.colors.grays.light} 100%
				);
				-webkit-background-clip: text;
				background-clip: text;
				color: transparent;
			}
		}

		.empty {
			i {
				color: ${props => props.theme.colors.grays.light};
			}
		}
	}
`;

export const Date = styled.div`
	${FontMixin({ size: 'small', height: 'large', weight: 'light' })}
	color: ${({ theme }) => theme.colors.grays.dark};
`;

export const AverageText = styled.p`
	${FontMixin({ size: 'small', height: 'large', weight: 'light' })}
	margin: 8px 0;
`;

export const Name = styled.p`
	${FontMixin({ size: 'small', height: 'large', weight: 'light' })}
`;
