import BaseTitle from '@babylon/ui-kit-base/components/text/title';
import { Container } from '@babylon/ui-kit-styles/common/mixins/container.styled';
import type { FC } from 'react';

import { Average } from '../../average';
import { ReviewList } from '../../reviewList';
import * as S from './styled';
import type { TrustedShopsDesktopProps } from './types';

export const TrustedShopsDesktop: FC<TrustedShopsDesktopProps> = ({ props }) => {
	const {
		Title: { title: { content = null } = {} } = {},
		reviewList,
		literals,
		average,
		totalReviews,
		reviewUrl,
	} = props;
	const { date_text } = literals ?? {};
	return (
		<Container data-testid='TrustedShopsDesktop'>
			{content && <BaseTitle title={content} />}
			<S.Wrapper>
				<Average average={average} literals={literals} totalReviews={totalReviews} reviewUrl={reviewUrl} />
				<ReviewList list={reviewList} literal={date_text} views={4} pagination={false} arrows={true} />
			</S.Wrapper>
		</Container>
	);
};

export * from '../../types';
export default TrustedShopsDesktop;
