import Slider from '@babylon/ui-kit-structures/components/others/slider';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

import { AverageStars } from '../renderStars/styled';

export const Carousel = styled(Slider)`
	width: calc(100% - 160px);
	padding-left: 10px;

	@media (max-width: ${props => props.theme.breakpoints.md}px) {
		width: 90%;
		padding-left: 0;
	}

	.splide {
		position: relative;
		width: 100%;
		padding: 0 24px;
		overflow: hidden;
	}
`;
export const Wrapper = styled.div`
	${FlexMixin()}
`;

export const Title = styled.p`
	${FontMixin({ size: 'medium', height: 'large', weight: 'medium' })}
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin: 8px 0 0;
`;
export const Text = styled.p`
	${FontMixin({ size: 'medium', height: 'large', weight: 'light' })}
	line-height: 20px;
	flex-grow: 1;
	margin: 8px 0;
	padding-right: 14px;

	max-height: 140px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 6px;
		background-color: ${({ theme }) => theme.colors.grays.light};
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: ${({ theme }) => theme.colors.grays.light};
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.colors.grays.dark};
		border-radius: 5px;
	}
`;

export const Name = styled.p`
	${FontMixin({ size: 'small', height: 'large', weight: 'light' })}
`;
export const Review = styled.div`
	margin-left: 35px;
	padding: 4px;
	color: ${({ theme }) => theme.colors.grays.darker};
	${AverageStars} {
		i {
			font-size: 22px;
		}
	}
`;
