import Title from '@babylon/ui-kit-base/components/text/title';
import { Container } from '@babylon/ui-kit-styles/common/mixins/container.styled';
import type { FC } from 'react';

import { Average } from '../../average';
import { ReviewList } from '../../reviewList';
import * as S from './styled';
import type { TrustedShopsMobileProps } from './types';

export const TrustedShopsMobile: FC<TrustedShopsMobileProps> = ({ props }) => {
	const { title, reviewList, literals, average, totalReviews, reviewUrl } = props;
	const { date_text } = literals ?? {};

	return (
		<Container data-testid='TrustedShopsMobile'>
			{title && (
				<S.TitleAverage>
					<Title title={title} />
				</S.TitleAverage>
			)}
			<S.Wrapper>
				<Average average={average} literals={literals} totalReviews={totalReviews} reviewUrl={reviewUrl} />
				<ReviewList list={reviewList} literal={date_text} views={1} pagination={true} arrows={false} />
			</S.Wrapper>
		</Container>
	);
};

export * from '../../types';
export default TrustedShopsMobile;
